
$txt-color: #B9B9BF;
$white: #fff;
$color-hover: #14C2A3;
$color-st2 :#D6D0E8;
$font-main: 'Chakra Petch', sans-serif;
$font-main2: 'IBM Plex Mono', monospace;
$bg-main: #100D1E;
$header-height: 100px;
$bgr-body :#0c1226;
$bg-2 :#14C2A3;
$bg-3 :#141A31;
$bg-4 :rgba(34,183,143,0.1);
$border-width: 2px;
$corner-size: 3rem;
$font-w6:600;
$font-w7:700;
$color-main1 :#B9B9BF; 
$color-main2 :#565660; 
$color-3 :#0e2230; 
$w100:100%;
$mb-30: 30px;
$mg32: 32px;
$mb-40: 40px;
$ml-30: -30px;
$center: center;

$padding-right:18px;

$box-shadow: 0 0px 32px 0 rgba(34, 183, 143, 0.5);

$boder-bt: 1px solid rgba(255,255,255,0.2);
$bg-gradient: linear-gradient(to bottom, #22B78F 10%, #E9E9E9 100%);
$bg-gradient2: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.13%, #161616 100%);
$bg-gradient3: linear-gradient(90deg, rgba(0, 0, 0, 0) 0.13%, #161616 100%);
$res_1600: 1600px;
$res_1440: 1440px;
$res_1200: 1200px;
$res_991: 991px;
$res_767: 767px;
$res_600: 600px;
$res_500: 500px;
$res_400: 500px;

$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1500: 1500px;
$desktop-1900 : 1900px;

// 
$bg-main: #fff;
$primary: #3772ff;
$onsurface: #23262f;
$secondary: #777e90;
$surface: #f7f7f7;

// Font
$font-main: "Zen Dots";
$font-second: "Poppins", sans-serif;

$header-height: 88px;
$header-shrink-height: 90px;

$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1900: 1900px;

$box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
$bg-overlay: rgba(0, 0, 0, 0.5);

$transition: all 0.3s ease-in-out;

